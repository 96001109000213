@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;700;1000&display=swap");
body {
  font-family: "Sora", sans-serif;
  font-size: 18px;
  color: #382843;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sora", sans-serif;
  font-weight: 1000;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.link {
  font-size: 17px;
  cursor: pointer;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.logo-pasaporte {
  width: 200px;
}
.logo-expogame {
  max-width: 275px;
}
@media (max-width: 768px) {
  .logo-pasaporte,
  .textos-banner,
  .logo-expogame {
    max-width: 200px !important;
  }
}

@media (max-width: 410px) {
  .logo-pasaporte,
  .textos-banner,
  .logo-expogame {
    max-width: 150px !important;
  }
}

@media (max-width: 360px) {
  .logo-pasaporte,
  .textos-banner,
  .logo-expogame {
    max-width: 100px !important;
  }
}

.form-outline .form-control.select-input::placeholder {
  color: #fff !important;
}

.input-group.input-phone span.input-group-text {
  color: #fff !important;
}

.form-text {
  color: #bbb !important;
}

.hamburguer-menu,
.bell-notification {
  background-color: transparent !important;
  box-shadow: none !important;
}

.btn-close {
  width: 1.5em;
  height: 1.5em;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
    center/1.5em auto no-repeat;
  opacity: 1;
}

.sidenav {
  width: 360px;
  background-color: #1e1e26;
}
.sidenav-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}
.sidenav-link {
  padding: 1.5rem 1.5rem;
  margin-bottom: 15px;
  /*border: 1px solid #dcd3e3;*/
  background: transparent;
  color: #fff;
}

.sidenav-link.active,
.sidenav-link:focus {
  color: #fff !important;
  background-color: #8b47d2 !important;
}

#rrss-text {
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  .sidenav {
    width: 80% !important;
  }
}
.sidenav-link:hover {
  color: #fff;
  background-color: #8b47d2;
  outline: none;
}

.side-close-session {
  position: absolute;
  bottom: 40px;
  width: 100%;
}
.btn-outline-dark {
  color: #382843 !important;
  border: 3px solid #382843;
  border-radius: 0;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.15em;
  line-height: 39px;
}
.button-primary {
  background-color: #8b47d2 !important;
  border-color: #8b47d2;
  color: #ffffff !important;
}
.button-primary:hover {
  background-color: #7f56c0 !important;
}
.button-secondary {
  background-color: #ff2079 !important;
  border-color: #ff2079;
  color: #fff !important;
}
.button-secondary:hover {
  background-color: #1fb99a !important;
  color: #382843 !important;
}
.button-light {
  background-color: #31e6ed;
  border-color: #31e6ed;
  color: #382843;
}
.button-light:hover {
  background-color: #e4e6ec;
}
.form-outline .form-control {
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
}
/* unfocused borders and label*/

.form-outline .form-control ~ .form-notch .form-notch-trailing {
  border-top: 3px solid #382843;
  border-right: 3px solid #382843;
  border-bottom: 3px solid #382843;
}
.form-outline .form-control ~ .form-notch .form-notch-middle {
  border-bottom: 3px solid #382843;
  border-top: 3px solid #382843;
}
.form-outline .form-control ~ .form-notch .form-notch-leading {
  border-top: 3px solid #382843;
  border-bottom: 3px solid #382843;
  border-left: 3px solid #382843;
}

.form-outline .form-control ~ .form-label {
  font-size: 16px;
  color: #fff;
  /*
  background: #fff;
  border-radius: 4px;
  */
}
/* focused borders and label*/
.form-outline .form-control ~ .form-notch:focus-visible {
  outline: 0 !important;
}
.form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border: none;

  border-left: 3px solid #ff2079 !important;
  box-shadow: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border: none;

  border-image: linear-gradient(90deg, #ff2079 10%, #8b47d2 40%) !important;

  border-image-slice: 1;
  border-width: 1px;
  border-style: solid;

  box-shadow: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border: none;

  border-image: linear-gradient(90deg, #8b47d2 40%, #31e6ed 90%);

  border-image-slice: 1;
  border-width: 1px;
  border-style: solid;

  border-left: none !important;

  box-shadow: none;
}

.form-outline .form-control:focus ~ .form-label {
  color: #fff;
  padding-top: 0;
}
.form-outline .form-control {
  background: transparent !important;
}
.form-outline .form-control ~ .form-label {
  top: 10px;
  background-color: #181820;
  border-radius: 4px;
  padding: 0 5px;
}
.form-outline:focus .form-label {
  top: 20px !important;
}
input:focus + label {
  /*color: white;*/
}
.form-outline .form-control:disabled {
  background-color: #202127 !important;
  color: #8d8e91 !important;
}
.banner-admin {
  background-image: linear-gradient(
    90deg,
    #31e6ed -5%,
    #8b47d2 41.48%,
    #ff2079 90.7%
  );
}
.banner-gradient {
  background-image: url(./img/tramas/texturaBannerWeb.svg),
    linear-gradient(90deg, #31e6ed -5%, #8b47d2 41.48%, #ff2079 90.7%);
  background-position: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .page-welcome {
    /*
      background-image: url(./img/tramas/fondoTextura-400x860.svg), linear-gradient(90deg, #31e6ed -5%, #8b47d2 41.48%, #FF2079 90.7%) !important;
      */
    background-image: url(./img/v2/background.png) !important;
    background-position: center !important;
    background-size: cover !important;
  }
  .banner-gradient {
    background-image: url(./img/tramas/texturaBannerMobile.svg),
      linear-gradient(90deg, #31e6ed -5%, #8b47d2 41.48%, #ff2079 90.7%) !important;
  }
}
/* PAGES */
.page-welcome {
  /*
  background-image: url(./img/tramas/fondoTextura-1440x1024.svg), linear-gradient(180deg, #31e6ed -5%, #8b47d2 41.48%, #FF2079 90.7%);
    */
  background-image: url(./img/v2/background.png) !important;
  background-position: center !important;
  background-size: cover !important;
}
.page-cubes {
  background-image: url(./img/v2/background.png) !important;
  background-position: center !important;
  background-size: cover !important;
}
@media (max-width: 768px) {
  .page-cubes {
    background-size: 70px;
  }
  .banner-gradient {
    padding: 1rem 0;
  }
}

.tag-level {
  background-color: transparent !important;
  font-weight: 400;
  font-size: 16px;
  border: 2px solid #ff1889;
  border-radius: 25px;
  color: #ff1889;
  width: fit-content;
  padding: 2px 15px;
  line-height: 22px;
  box-sizing: border-box;
  text-transform: uppercase;
}

.tag-prizes {
  background: #8b47d2 !important;
  border: 2px solid #382843;
  font-weight: 700;
  font-size: 22px;
  border-radius: 4px;
  color: #fff;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 20px;
  line-height: 30px;
  box-sizing: border-box;
}
.points-title {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
}
.points-subtitle {
  font-weight: 1000;
  font-size: 34px;
  line-height: 38px;
  text-align: center;
  color: #ff2079;
  text-transform: uppercase;
}
.icon-cristal {
  position: absolute;
  bottom: 6px;
  margin-left: 0px;
}

.icon-cristal2 {
  position: absolute;
  bottom: 0px;
  margin-left: 6px;
  max-width: 24px;
}

.gamerExperiencePoints {
  -webkit-text-fill-color: #1e1e26;
  font-weight: 1000;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-family: "Cairo", sans-serif;
  font-size: 50px;
  line-height: 50px;
  background: #31e6ed;
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 10px transparent;
  letter-spacing: -0.035em;
}

.gamerCurrentScore {
  -webkit-text-fill-color: #1e1e26;
  font-weight: 1000;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-family: "Cairo", sans-serif;
  font-size: 100px;
  line-height: 100px;
  background: linear-gradient(0deg, #31e6ed 0%, #8b47d2 56.23%, #ff2079 98.98%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 10px transparent;
  letter-spacing: -0.035em;
}

.gamerCurrentScore2 {
  -webkit-text-fill-color: #1e1e26;
  font-weight: 850;
  text-align: right;
  padding-right: 10px;
  text-transform: uppercase;
  position: relative;
  font-family: "Cairo", sans-serif;
  font-size: 36px;
  line-height: 36px;
  background: linear-gradient(0deg, #31e6ed 0%, #8b47d2 56.23%, #ff2079 98.98%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 10px transparent;
  letter-spacing: -0.035em;
  min-width: 75px;
}

.exp-points {
  font-weight: 1000;
  font-size: 100px;
  line-height: 100px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.exp-points svg {
  /*display: block;*/
  height: 100px;
}
.exp-points text {
  fill: #fff;
  stroke: #382843;
  stroke-width: 6;
  letter-spacing: -0.035em;
}

.cohete {
  width: calc(50% + 8vh);
  text-align: end;
}
.cohetelinea {
  background-image: url(./img/cohete-linea.png);
  background-repeat: repeat-x;
  background-size: contain;
  width: 100%;
  height: 120px;
}
@media (max-width: 768px) {
  .cohetelinea,
  .cohete img {
    max-height: 100px;
  }
}
.panel-icons {
  color: #ff2079;
}
.page-admin {
  background-color: #f4f7fb;
}
.bg-admin {
  background-image: url(./img/bg-admin.jpg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

/* Avatar */
.txt-selec-avatar,
.txt-register {
  width: 300px;
}
@media (max-width: 768px) {
  .txt-selec-avatar,
  .txt-register {
    width: 220px;
  }
}
.avatarselc-nombre {
  max-width: 50%;
  max-height: 50px;
}
.avatarimg {
  max-width: 300px;
}
.modal-cubes {
  background-image: url(./img/tramas/Trama_Cubos_Full.svg);
  background-position: center;
  background-repeat: repeat;
  background-size: 80px;
}
.edit-profile-link:hover,
.edit-profile-link:focus {
  background-color: transparent;
}
.input-password .form-outline {
  width: 100%;
  display: inline-flex;
  height: 44px;
}
.icon-button-eye {
  width: 50px;
  height: 44px;
  padding: 0 !important;
  background: transparent !important;
  color: #686868 !important;
  font-size: 17px;
  border-top: 3px solid #382843 !important;
  border-right: 3px solid #382843 !important;
  border-bottom: 3px solid #382843 !important;
  margin-left: -4px !important;
  box-shadow: none !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.password-selected {
  border-top: 3px solid #31e6ed !important;
  border-right: 3px solid #31e6ed !important;
  border-bottom: 3px solid #31e6ed !important;
}

.password-selected2 {
  border-top: 3px solid #31e6ed !important;
  border-right: 3px solid #31e6ed !important;
  border-bottom: 3px solid #31e6ed !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  border-color: #8b47d2;
  background: #8b47d2;
  border-radius: 4px 4px 0px 0px;
}

.select-wrapper .select-arrow {
  color: #686868 !important;
  font-size: 1.4rem;
  right: 16px;
  top: 0;
}
.select-wrapper .select-arrow::before {
  font-family: "Font Awesome 5 Free" !important;
  content: "\f0d7" !important;
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.form-text.mb-3 {
  font-size: 0.8em;
}
.input-phone .input-group-text {
  height: 45px !important;
  border-right: 0 !important;
  border-left: 3px solid #382843;
  border-top: 3px solid #382843;
  border-bottom: 3px solid #382843;
  background: transparent;
  position: absolute !important;
  top: 0 !important;
}
.input-phone .form-outline input {
  height: 45px;
}
.input-phone .form-outline {
  width: calc(100% - 52px);
  margin-left: auto !important;
}
.input-phone .form-outline .form-control ~ .form-notch .form-notch-middle {
  border-top: 3px solid #382843 !important;
}
.input-phone
  .form-outline
  .form-control:focus
  ~ .form-notch
  .form-notch-middle {
  border-top: 3px solid #ff2079 !important;
}
#rrss-text > a {
  font-size: 22px;
  color: #fff;
}
#rrss-text > a:hover {
  color: #ff2079;
}
#offcanvas-close {
  background: none;
  box-shadow: none;
}
.progress {
  border-radius: 20px;
  height: 36px !important;
  border: 2px solid #ff2079;
  background: #252431;
}
.progress-bar {
  background: linear-gradient(
    90deg,
    #31e6ed 0%,
    #8b47d2 56.23%,
    #ff2079 98.98%
  );
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 18px;
  font-weight: 600;
  text-align: end;
  padding-right: 20px;
}
.color-1 {
  color: #382843;
}
.zone,
.challenge {
  border: 3px solid #ff2079 !important;
  border-radius: 4px;
}
.zone h6,
.challenge h6 {
  color: #fff;
}

.challenge {
  min-height: 165px;
}
.zone .card-body .button-primary {
  padding: 10px 5px !important;
  font-size: 16px;
  line-height: 16px;
  min-width: 130px !important;
}
label.success {
  background: #25cead;
  padding: 2px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
}
label.warning {
  background: #fcd607;
  padding: 2px 10px;
  border-radius: 8px;
  color: #282c34;
  font-size: 16px;
  line-height: 22px;
}
label.danger {
  background: #ff2079;
  padding: 2px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
}
.challengeScore {
  -webkit-text-fill-color: #fff;
  font-weight: 1000;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-size: 50px;
  line-height: 50px;
  -webkit-text-stroke: 3px #382843;
  letter-spacing: -0.045em;
}
.badge-success {
  background-color: #ffd628;
  color: #382843;
}

/* ============== 
  FAQ 
=================*/

.faq .accordion-item {
  margin-bottom: 7px;
  border: 0;
  background: transparent !important;
  color: #fff !important;
}
.faq .accordion-header {
  border-radius: 4px;
  background: transparent !important;
  border: 2px solid var(--morado-oscuro, #656) !important;
}
.faq .accordion-button {
  background: transparent !important;
  color: #fff !important;
}

.accordion-item-open .accordion-header {
  /*border: 2px solid var(--fucsia, #ff2079) !important;*/
  border: none;
  border-image: linear-gradient(
    90deg,
    #ff2079 10%,
    #8b47d2 40%,
    #31e6ed 90%
  ) !important;
  border-image-slice: 1 !important;
  border-width: 3px !important;
  border-style: solid !important;
}

.faq .accordion-button:not(.collapsed) {
  background: transparent !important;
}

button.primary.v2 {
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url(./img/v2/button-border.png) !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 21px;
  font-family: Sora, sans-serif;
  font-weight: bold;
}

button.secondary.v2 {
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url(./img/v2/button-border-secondary.png) !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 21px;
  font-family: Sora, sans-serif;
  font-weight: bold;
}

button.terciary.v2 {
  background-color: #8b47d2;
  /*
  background-image: url(./img/v2/button-border-secondary.png) !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  */
  color: #fff;
  text-transform: uppercase;
  font-size: 21px;
  font-family: Sora, sans-serif;
  font-weight: bold;
}

span.v2,
p.v2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 21px;
  font-family: Sora, sans-serif;
  /*
    how to letter space in css
  */
  letter-spacing: 5px;
}

h1.v2.regular {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  font-family: Sora, sans-serif;
  letter-spacing: 2px;
}

h2.v2.regular {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  font-family: Sora, sans-serif;
  letter-spacing: 2px;
}

h1.v2.avatar {
  text-transform: uppercase;
  color: #ff2079;
  font-weight: bold;
  font-size: 30px;
  font-family: Sora, sans-serif;
  letter-spacing: 2px;
}

h5.v2.regular {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 0.15em;
  font-family: Sora, sans-serif;
}

h6.v2.regular {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0;
  font-family: Sora, sans-serif;
}

h6.v2.mini {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0;
  font-family: Sora, sans-serif;
}

p.v2.regular,
span.v2.regular {
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0;
  font-family: Sora, sans-serif;
  text-transform: none;
}

p.v2.bold,
span.v2.bold {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0;
  font-family: Sora, sans-serif;
  text-transform: none;
}

input.form-control:focus {
  border: none;
  border-image: linear-gradient(90deg, #ff2079 10%, #8b47d2 40%, #31e6ed 90%);
  border-image-slice: 1;
  border-width: 3px;
  border-style: solid;
  background-color: none;
}

.modal-content {
  border-radius: 0 !important;
  background-color: #16171b !important;
  border: none;
  border-image: linear-gradient(90deg, #ff2079 10%, #8b47d2 40%, #31e6ed 90%);
  border-image-slice: 1;
  border-width: 3px;
  border-style: solid;
}

.modal-content .modal-header {
  border-bottom: none;
}

.modal-content .modal-footer {
  border-top: none;
}

.card {
  background-color: #16171b !important;
  color: #fff;
  border: 1px solid #404040;
}
.card-header {
  background-color: #16171b !important;
  border-bottom: 1px solid #404040;
  color: #fff;
}

.tag-difficulty {
  font-weight: 400;
  font-size: 16px;
  border-radius: 25px;
  width: fit-content;
  padding: 2px 15px;
  line-height: 22px;
  box-sizing: border-box;
  text-transform: none;
  background-color: #aaa !important;
}

.tag-difficulty-easy {
  background-color: #31e6ed !important;
  color: #382843 !important;
}

.tag-difficulty-medium {
  background-color: #8b47d2 !important;
  color: #fff !important;
}

.tag-difficulty-hard {
  background-color: #ff2079 !important;
  color: #fff !important;
}

.challengeTextWrap {
  word-wrap: break-word !important;
}

input,
select {
  color: #fff !important;
}

input::focus {
  background: transparent !important;
  color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:-webkit-autofill:hover {
  -webkit-text-fill-color: #fff;
}

input:-webkit-autofill:-webkit-autofill {
  -webkit-text-fill-color: #fff;
}
